import React, { useMemo } from 'react';
import classnames from 'classnames';
import { DeepDiff } from 'deep-diff';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

// mui
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import { FormGrid, Tooltip } from 'components';

import { RISK_DEFINITION_GENERAL, RISK_ENDORSEMENT_FIELDS } from 'consts/quote-bind';
import { QB_RISK_DEFINITION, RISK_COUNTRIES, useGetRiskCountries, useGetRiskDefinitions } from 'lib/quoteBind';
import * as utils from 'utils';

// app
import styles from './EndorsementComparison.styles';

const jsPathToJsonPath = (path) => {
  let formattedPath = path?.replace('[', '.');
  formattedPath = formattedPath?.replace('].', '.');
  return formattedPath;
};

// TODO: TECH DEBT - refactor
export const EndorsementComparisonDetails = ({ riskDataValues, currentEndorsementValues, jsonPatchData, riskType }) => {
  const classes = makeStyles(styles, { name: 'EndorsementComparison' })();
  const { data: riskDefinitionData, isLoading: riskDefinitionsLoading } = useGetRiskDefinitions(QB_RISK_DEFINITION, riskType);
  const { data: countries, isLoading: isLoadingCountries } = useGetRiskCountries(RISK_COUNTRIES);

  const endorsementDiff = DeepDiff.diff(riskDataValues, currentEndorsementValues) || [];

  const formattedJsonPatch = useMemo(
    () =>
      jsonPatchData
        ? JSON.parse(jsonPatchData)?.map((change) => {
            const jsonPath = change.path?.replace('/', '');
            const finalPath = jsonPath?.replace(/\//g, '.');
            return { ...change, path: finalPath };
          })
        : [],
    [jsonPatchData]
  );

  if (riskDefinitionsLoading || isLoadingCountries) return null;

  const riskDefinitionsFieldsByType = riskDefinitionData?.data?.product || [];

  const riskDefinitionsFields = [...riskDefinitionsFieldsByType, ...RISK_ENDORSEMENT_FIELDS];

  const definitionsFields = riskDefinitionsFields.filter((definition) => definition.type !== 'LABEL') || [];
  const groups = Object.keys(utils.risk.getGroups(definitionsFields));

  const checkIsFieldEdited = (name) => {
    let isEdited = false;
    formattedJsonPatch?.forEach((change) => {
      const formattedName = jsPathToJsonPath(name);
      if (formattedName === change.path && change?.op === 'replace') {
        isEdited = true;
      }
    });

    return isEdited;
  };

  const fieldEditedValues = (name, field) => {
    const previousValue = get(riskDataValues, `${name}`);

    const formattedName = jsPathToJsonPath(name);
    const currentValue = formattedJsonPatch?.find((change) => change.path === formattedName)?.value;

    return previousValue || currentValue ? (
      <Box minWidth={150}>
        <Grid container spacing={1}>
          <Grid item xs={3} align="left">
            <Typography variant="body2" align="left" component="span">
              {utils.string.t('products.wasLabel')}:
            </Typography>
          </Grid>
          <Grid item xs={9} align="left">
            <Typography variant="body2" align="left" component="span" className={classes.quoteValue}>
              {previousValue || previousValue === false || previousValue === 0 ? renderValue(field, previousValue) : null}
            </Typography>
          </Grid>
          <Grid item xs={3} align="left">
            <Typography variant="body2" align="left" component="span">
              {utils.string.t('products.nowLabel')}:
            </Typography>
          </Grid>
          <Grid item xs={9} align="left">
            <Typography variant="body2" align="left" component="span" className={classes.quoteValue}>
              {currentValue || currentValue === false ? renderValue(field, currentValue) : null}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    ) : null;
  };

  const renderValue = (field, v) => {
    let suffix = '';
    const value = typeof v !== 'undefined' ? v : currentEndorsementValues[field.name];
    let newValue = value;

    switch (field.type) {
      case 'DOUBLE':
        const isPercent = Boolean(field?.validation?.percent);
        newValue = utils.string.t(`format.${isPercent ? 'percent' : 'currency'}`, { value: { number: value } });
        break;
      case 'BOOLEAN':
        newValue = utils.risk.checkBoolean(value);
        break;
      case 'CHECKBOX':
        newValue = utils.risk.checkBoolean(value);
        break;
      case 'DATE':
        newValue = utils.string.t(`format.date`, { value: { date: value } });
        break;
      case 'ID':
        newValue = typeof v !== 'undefined' ? v : currentEndorsementValues?.[field?.targetField]?.name || '';
        break;

      case 'SELECT': {
        if (field.autocomplete) {
          if (utils.generic.isValidArray(value)) {
            newValue = '';
            for (const singleValue of value) {
              newValue += `${singleValue?.label || singleValue},`;
            }
            newValue = newValue.slice(0, -1);
          } else newValue = value?.label || value;
        } else {
          let options = [];

          if (field.name === 'countryOfOrigin') {
            options = countries;
          } else if (utils.generic.isValidArray(field.options, true)) {
            options = field.options;
          }

          const option = options.find((o) => String(o.value) === String(value)) || {};

          newValue = option?.label !== 'Select...' ? option.label : value || '';
        }
        break;
      }
      case 'ARRAY': {
        newValue = value?.map((item, index) => {
          const isLastValue = index === value.length - 1;
          const filteredItem = Object.entries(item).filter(([_, v]) => v);
          const mappedItem = filteredItem.map(([key, v], itemIndex) => {
            const def = field.arrayItemDef.find(({ name }) => name === key);
            const isLastItem = itemIndex === filteredItem.length - 1;
            return (
              <React.Fragment key={key}>
                {renderValue(def, v)}
                {!isLastItem ? ' - ' : ''}
                {isLastItem && !isLastValue ? '; ' : ''}
              </React.Fragment>
            );
          });
          return mappedItem;
        });
        break;
      }
      case 'RADIO': {
        newValue = value || '';
        break;
      }

      default:
        break;
    }

    // add prefix/suffix for specific fields
    if (field.name === 'distanceToCoast' && value) {
      suffix = ` ${utils.string.t('map.unit.miles')}`;
    }
    // the extra <span /> is used to prevent Material-UI complaining about not receiving a ReactNode
    // this happens if the value is true/false/undefined/null...
    // this workaround prevents errors in case some invalid values fall through the cracks
    return utils.generic.isValidObject(newValue) ? null : (
      <span>
        {newValue}
        {suffix}
      </span>
    );
  };

  return (
    <div style={{ padding: '20px 40px' }}>
      <FormGrid container spacing={3} data-testid="risk-data">
        {groups.map((group) => {
          const fields = utils.risk.getFieldsByGroup(definitionsFields, group) || [];

          return group === RISK_DEFINITION_GENERAL ? (
            <FormGrid item xs={12} data-testid={`risk-data-${group}`} key={group}>
              <Box className={classes.card}>
                <Box p={2} className={classes.cardTitle}>
                  <Typography variant="h3" className={classes.cardTitleHeading}>
                    {startCase(toLower(group))}
                  </Typography>
                </Box>
                <Box p={2}>
                  <FormGrid container spacing={1}>
                    {fields.map((field) => {
                      const value = field?.targetField
                        ? currentEndorsementValues[field.targetField]?.name
                        : currentEndorsementValues[field.name];

                      const fieldName = field?.targetField ? `${field.targetField}.name` : field.name;
                      const condition = utils.risk.getCondition(field, definitionsFields);
                      const refValueCondition = condition && currentEndorsementValues && get(currentEndorsementValues, `${condition.name}`);
                      const isConditionValid = condition && utils.risk.isConditionValid(condition, refValueCondition);
                      const isFieldEdited = checkIsFieldEdited(fieldName);
                      const isHidden = utils.risk.isHiddenField(field);

                      return !isHidden ? (
                        condition === undefined || (condition && isConditionValid) ? (
                          <FormGrid item xs={12} sm={4} key={`${field.label}-${value}`}>
                            <FormGrid container spacing={1} key={field.label}>
                              <FormGrid item xs={6}>
                                <Typography variant="body2" component="span">
                                  {field.label}
                                </Typography>
                              </FormGrid>
                              <FormGrid item xs={6} nestedClasses={{ root: classes.flexGrid }}>
                                <Typography
                                  variant="body2"
                                  component="div"
                                  style={{ fontWeight: 'bold' }}
                                  className={classnames({ [classes.edited]: isFieldEdited })}
                                >
                                  {renderValue(field)}
                                </Typography>
                                {isFieldEdited ? (
                                  <Tooltip title={fieldEditedValues(fieldName, field)} placement="top" rich style={{ marginLeft: 5 }}>
                                    <InfoOutlinedIcon fontSize="small" />
                                  </Tooltip>
                                ) : null}
                              </FormGrid>
                            </FormGrid>
                          </FormGrid>
                        ) : (
                          <FormGrid item xs={12} sm={4} key={`${field.label}-${value}`} />
                        )
                      ) : null;
                    })}
                  </FormGrid>
                </Box>
              </Box>
            </FormGrid>
          ) : (
            <FormGrid item xs={12} sm={6} md={4} data-testid={`risk-data-${group}`} key={group}>
              <Box className={classes.card}>
                <Box p={2} className={classes.cardTitle}>
                  <Typography variant="h3" className={classes.cardTitleHeading}>
                    {startCase(toLower(group))}
                  </Typography>
                </Box>
                <Box p={2}>
                  {fields
                    .filter((field) => Boolean(field.name) && field.type !== 'label')
                    .map((field) => {
                      const value = currentEndorsementValues[field.name] || null;
                      const isArrayColumn = utils.risk.isArrayColumn(field) && utils.generic.isValidArray(value);
                      const isArrayTable = utils.risk.isArrayTable(field) && utils.generic.isValidArray(value);
                      const isObject = utils.risk.isObject(field) && utils.generic.isValidObject(value);
                      const valueArray = isArrayColumn || isArrayTable ? value : [value];
                      const arrayKey = isArrayColumn || isArrayTable ? 'arrayItemDef' : isObject ? 'objectDef' : '';

                      const condition = utils.risk.getCondition(field, definitionsFields);
                      const refValueCondition = condition && currentEndorsementValues && get(currentEndorsementValues, `${condition.name}`);
                      const isConditionValid = condition && utils.risk.isConditionValid(condition, refValueCondition);

                      if (isArrayColumn || isArrayTable || isObject) {
                        const { name } = field;

                        const removedItems =
                          formattedJsonPatch
                            ?.map((change) => {
                              if (change.op === 'remove') {
                                const parent = change.path.split('.')[0];
                                const index = change.path.split('.')[1];
                                if (name === parent) {
                                  return riskDataValues[field.name][index];
                                }
                              }
                              return false;
                            })
                            .filter(Boolean) || [];

                        const addedItems =
                          formattedJsonPatch
                            ?.map((change) => {
                              if (change.op === 'add') {
                                const parent = change.path.split('.')[0];
                                const index = change.path.split('.')[1];
                                if (name === parent) {
                                  return currentEndorsementValues[field.name][index];
                                }
                              }
                              return false;
                            })
                            .filter(Boolean) || [];

                        const currentArray = get(currentEndorsementValues, `${name}`) ?? [];
                        const valueArrayAndRemoved = isArrayColumn || isArrayTable ? [...removedItems, ...currentArray] : valueArray;

                        let displayArray = [];
                        if (valueArrayAndRemoved?.length > 10) {
                          const changedItems = [...addedItems, ...removedItems];
                          const sliceLength = changedItems?.length > 5 ? 5 : 10;
                          displayArray = [
                            ...addedItems,
                            ...removedItems,
                            ...valueArrayAndRemoved
                              .filter((v) => !addedItems.includes(v) && !removedItems.includes(v))
                              .slice(0, sliceLength),
                          ];
                        } else displayArray = valueArrayAndRemoved;

                        const showMoreMessage = valueArrayAndRemoved?.length > 10 ? `... and ${valueArrayAndRemoved.length - 10} more` : '';
                        return condition === undefined || (Boolean(condition) && isConditionValid) ? (
                          <FormGrid spacing={2} container key={`${field.label}-${field.name}`}>
                            <FormGrid item xs={12}>
                              {field.label}
                            </FormGrid>

                            {displayArray.map((v, index) => {
                              const isRemoved = removedItems.includes(v);
                              const isAdded = addedItems.includes(v);

                              return (
                                <FormGrid item xs={12} key={`key-${index}`}>
                                  <Box
                                    className={
                                      displayArray?.length > 1
                                        ? `${classes.card} ${classes.cardArray} ${isRemoved ? `${classes.cardRemoved}` : ``}${
                                            isAdded ? `${classes.cardAdded}` : ``
                                          }`
                                        : ''
                                    }
                                  >
                                    {isRemoved ? (
                                      <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 20 }}>
                                        <RemoveCircleIcon fontSize="small" style={{ color: '#FF0000', marginRight: 10 }} />
                                        <Typography variant="h3" classes={{ h3: classes.removedTitle }}>
                                          Removed
                                        </Typography>
                                      </Box>
                                    ) : null}
                                    {isAdded ? (
                                      <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 20 }}>
                                        <AddCircleIcon fontSize="small" style={{ color: '#2CC6AB', marginRight: 10 }} />
                                        <Typography variant="h3" classes={{ h3: classes.addedTitle }}>
                                          Added
                                        </Typography>
                                      </Box>
                                    ) : null}
                                    {field[arrayKey]
                                      .filter((f) => Boolean(f.name))
                                      .map((arrayField) => {
                                        const fieldName = isObject
                                          ? `${field.name}.${arrayField.name}`
                                          : `${field.name}[${index}].${arrayField.name}`;

                                        const isFieldEdited = checkIsFieldEdited(fieldName);
                                        const isHidden = utils.risk.isHiddenField(arrayField);
                                        const isTitle = utils.risk.isTitleField(arrayField);

                                        return !isHidden || isTitle ? (
                                          <FormGrid spacing={1} container key={`${arrayField.name}-${arrayField.label}`}>
                                            <FormGrid item xs={6}>
                                              <Typography variant="body2" component="span">
                                                {arrayField.label}
                                              </Typography>
                                            </FormGrid>
                                            <FormGrid item xs={6} nestedClasses={{ root: classes.flexGrid }}>
                                              <Typography
                                                variant="body2"
                                                component="span"
                                                style={{ fontWeight: 'bold' }}
                                                className={classnames({ [classes.edited]: isFieldEdited && !isRemoved && !isAdded })}
                                              >
                                                {renderValue(arrayField, v[arrayField.name])}
                                              </Typography>
                                              {isFieldEdited && !isRemoved && !isAdded ? (
                                                <Tooltip
                                                  title={fieldEditedValues(fieldName, arrayField)}
                                                  placement="top"
                                                  rich
                                                  style={{ marginLeft: 5 }}
                                                >
                                                  <InfoOutlinedIcon fontSize="small" />
                                                </Tooltip>
                                              ) : null}
                                            </FormGrid>
                                          </FormGrid>
                                        ) : null;
                                      })}
                                  </Box>
                                </FormGrid>
                              );
                            })}

                            {showMoreMessage ? (
                              <FormGrid item xs={12}>
                                <Typography variant="body2" component="span">
                                  {showMoreMessage}
                                </Typography>
                              </FormGrid>
                            ) : null}
                          </FormGrid>
                        ) : null;
                      }
                      const isFieldEdited = checkIsFieldEdited(field.name);
                      const isHidden = utils.risk.isHiddenField(field);

                      return !isHidden && (condition === undefined || (condition && isConditionValid)) ? (
                        <FormGrid container spacing={2} key={`${field.name}-${field.label}`}>
                          <FormGrid item xs={6}>
                            <Typography variant="body2" component="span">
                              {field.label || field.title}
                            </Typography>
                          </FormGrid>
                          <FormGrid item xs={6} nestedClasses={{ root: classes.flexGrid }}>
                            <Typography
                              variant="body2"
                              component="span"
                              style={{ fontWeight: 'bold' }}
                              className={classnames({ [classes.edited]: isFieldEdited })}
                            >
                              {renderValue(field)}
                            </Typography>
                            {isFieldEdited ? (
                              <Tooltip title={fieldEditedValues(field.name, field)} placement="top" rich style={{ marginLeft: 5 }}>
                                <InfoOutlinedIcon fontSize="small" />
                              </Tooltip>
                            ) : null}
                          </FormGrid>
                        </FormGrid>
                      ) : null;
                    })}
                </Box>
              </Box>
            </FormGrid>
          );
        })}
      </FormGrid>
    </div>
  );
};
